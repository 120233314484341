@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

// General Overrides

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  @media screen and (min-width: 960px) {
    font-size: 62.5%;
  }
}

body {
  color: $black;
  background-color: $bg-blue;
  top: 0;
  z-index: 1;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }
}

a,
button {
  outline: 0 !important;
}

// Utils

.inline-block {
  display: inline-block;
}

.float-left {
  float: left;
}

.clear {
  clear: both;
}

// Responsive classes

.one-in-small {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

// Layout

.wrapper {
  @include container;
  max-width: 120rem;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;

  @media only screen and (max-width: 960px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &--nav {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 0rem 3rem;
    height: 80px;
    box-sizing: border-box;
    z-index: 9;
    background-color: $primary;
    padding-left: 0;
    padding-right: 0;

    @media only screen and (max-width: 960px) {
      padding: 0;
      height: 5rem;
      text-align: center;
    }
  }

  &--fixed {
    position: fixed;
    background-color: $white;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.06);
    z-index: 99;

    .lnk--logo {
      display: none;
    }

    .lnk--logo-blue {
      display: block;

      .lnk--logo {
        display: block;
      }
    }

    .lnk--nav {
      color: #313131 !important;
    }

    @media only screen and (max-width: 960px) {
      display: none !important;
    }
  }

  &--header {
    position: relative;
    height: 100%;
    display: table;
    padding-bottom: 0;
    z-index: 8;
  }

  &--section {
    clear: both;
    padding: 13rem 0;
    text-align: left;
    display: inline-block;

    @media only screen and (max-width: 960px) {
      padding: 6rem 2rem;
    }

    &--faq {
      padding-top: 0;
    }

    &--desc {
      padding: 8rem 2rem;
    }
  }

  &--footer {
    padding: 6rem 0;
  }
}

// Navigation

.nav {
  &__logo {
    padding-top: 0;
    float: left;
    padding-bottom: 0;
    margin-top: 1.4rem;

    @media only screen and (max-width: 960px) {
      a {
        width: 100%;
        text-align: center;
        margin-top: 1rem;

        img {
          width: 100px;
        }
      }
    }
  }

  &__links {
    float: right;
    display: none;

    @media only screen and (min-width: 960px) {
      display: block;
    }
  }
}

// Header

.header {
  // margin-top: 8rem;
  background: $soft-blue url(/images/bg-shapes.png) center 24rem no-repeat;
  height: 87rem;

  @media only screen and (max-width: 960px) {
    height: auto;
    background-size: contain;
    background-position: center bottom;
  }

  &__stamp {
    position: absolute;
    bottom: 30px;
    right: 342px;
    z-index: 9;

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7.6rem;
    margin-top: -7rem;
    background: transparent url(/images/play-red.png) no-repeat center center;
    width: 17rem;
    height: 17rem;
    cursor: pointer;
    z-index: 9;

    @media only screen and (max-width: 960px) {
      top: 32rem;
      margin-right: -8rem;
      right: 50%;
    }

    &:hover {
      filter: saturate(2);
    }

    animation-name: pulse_animation;
    animation-duration: 5000ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &__sub {
    background: transparent url(/images/bg-waves.png) top left repeat-x;
    width: 100%;
    margin-top: -20rem;
    display: block;
    float: left;

    @media only screen and (max-width: 960px) {
      background: transparent;
      margin-top: -7rem;
    }

    &--wrapper {
      background-color: $white;
      margin-top: 4rem;
      padding-top: 18rem;
      padding-bottom: 3rem;
      text-align: center;
      position: relative;

      @media only screen and (max-width: 960px) {
        padding-top: 6rem;
        height: 20rem;
      }
    }
  }

  &__picture {
    display: block;
    text-align: center;
    width: 100%;
    display: inline-block;

    img {
      display: inline-block;
    }
  }

  &__claim {
    @include susy-media(30rem) {
      width: 100%;
      text-align: center;
      color: #fff;
      float: none;
      margin-left: 0;

      h2 {
        font-size: 5.5rem;
        letter-spacing: -0.7px;
      }
    }

    margin-top: 11.5rem;
    float: none;

    @media only screen and (max-width: 960px) {
      margin-top: 8rem;
      display: block;

      h2 {
        font-size: 2.5rem;
        color: $white;
        line-height: 1.4;
      }
    }
  }

  &__note {
    margin: 2rem auto 0;
  }

  &__logos--left,
  &__logos--right {
    position: absolute;
    bottom: 3rem;

    @media only screen and (max-width: 1200px) {
      img {
        max-width: 150px;
        max-height: 50px;
      }
    }

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  &__logos--left {
    left: 3rem;

    img {
      display: inline-block;
      float: left;
      margin-right: 3rem;
    }
  }

  &__logos--right {
    right: 3rem;

    img {
      display: inline-block;
      float: right;
      margin-right: 3rem;
    }
  }

  &__logos {
    @media only screen and (max-width: 1480px) {
      clear: both;
      margin-top: 100px;

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

.features {
  background-color: $soft-red;
  margin-bottom: 6rem;
  width: 100%;
  display: block;
  padding: 0rem 0 1rem;

  // float: left;
  @media only screen and (max-width: 960px) {
    margin-bottom: 0rem;
  }

  &__item {
    @include span(3);

    @media only screen and (max-width: 480px) {
      @include span(6);
      height: 16rem;
    }

    color: $white;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding: 10rem 0 1rem;

    &--kids {
      background: transparent url(/images/features-kids.png) no-repeat center 3rem;
    }

    &--age {
      background: transparent url(/images/features-age.png) no-repeat center 3.9rem;
    }

    &--time {
      background: transparent url(/images/features-time.png) no-repeat center 3.9rem;
    }

    &--place {
      background: transparent url(/images/features-place.png) no-repeat center 3rem;
    }
  }
}

//Sections

.section {
  text-align: center;

  &--desc {
    background-color: $white;
    margin: 13rem 0;
  }

  &--left {
    background-position: right 15% center;
    background-repeat: no-repeat;
  }

  &--right {
    background-position: left 15% center;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 960px) {

    &--left,
    &--right {
      background-position: center top 40px;
      background-size: 100px;
      padding-top: 15rem;
    }
  }

  &--three {
    &__title {
      text-align: center;
      margin-bottom: 8rem;
      font-weight: 400;
      color: $black !important;
    }

    &__item {
      @include span(4) text-align: center;
      background-size: 50%;
      padding-top: 13rem;

      &--small-1 {
        background: transparent url(/images/small-1.png) no-repeat center top;
      }

      &--small-2 {
        background: transparent url(/images/small-2.png) no-repeat center top 0.3rem;
      }

      &--small-3 {
        background: transparent url(/images/small-3.png) no-repeat center top 1.2rem;
      }

      &__title {
        color: $primary;
        font-size: 1.8rem;
      }

      &__body {
        padding: 0 15%;
        color: $gray-text;
        font-size: 1.6rem;
      }
    }

    @media only screen and (max-width: 960px) {
      &__item {
        @include span(12);
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 4rem;

        &__body {
          padding: 0 1rem;
        }
      }
    }
  }

  &--left--text {
    @include span(5);
  }

  &--right--text {
    @include push(7);
    @include span(5);
  }

  @media only screen and (max-width: 960px) {
    &--left--text {
      @include span(12);
    }

    &--right--text {
      @include span(12);
      @include push(0);
    }
  }

  &--big-1 {
    background-image: url(/images/big-1.png);
  }

  &--prize-1 {
    background-image: url(/images/klicksafe-prize-lg.png);
  }

  &--big-2 {
    background-image: url(/images/big-2.png);
  }

  &--big-3 {
    background-image: url(/images/big-3.png);
  }

  &__title {
    color: $white;

    &--faq {
      background: transparent url("/images/faq-face.png") no-repeat left center;
      padding: 3rem 0 6rem 12rem;

      &__speech-bubble {
        background-color: $white;
        font-weight: normal;
        padding: 2.5rem 3rem;
        font-size: 2.4rem;
        border-radius: 3rem;
        border-bottom-left-radius: 1.8rem;
        position: relative;
        color: $black;

        &::after {
          content: "";
          position: absolute;
          border-style: solid;
          border-width: 1rem 1rem 1rem 0;
          border-color: transparent $white;
          display: block;
          width: 0;
          z-index: 1;
          left: -0.8rem;
          top: 3rem;
        }
      }

      @media only screen and (max-width: 960px) {
        &__speech-bubble {
          font-size: 1.8rem;
          padding: 2.5rem 2rem;
        }
      }
    }
  }

  &__body {
    color: $white;
  }

  &__more {
    display: none;
    background-color: $white;
    margin-top: -13rem;
    padding: 8rem 2rem 5rem;

    &--title {
      padding-bottom: 0.5rem;
    }

    &__item {
      @include span(5);
      padding-right: 2%;

      &--two {
        @include push(2);
      }
    }

    @media only screen and (max-width: 960px) {
      margin-top: -6rem;

      .wrapper {
        padding: 0;
      }

      &__item {
        @include span(12);
        padding-right: 0;
        margin-bottom: 4rem;

        &--two {
          @include push(0);
        }
      }
    }
  }
}

.faq {
  &__item {
    cursor: pointer;
    width: 100%;
    padding: 2rem 3rem;
    margin-bottom: 0.3rem;
    box-sizing: border-box;
    background: $white url("/images/plus.png") no-repeat right 2rem center;

    &:first-child {
      border-radius: 0.4rem 0.4rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.4rem 0.4rem;
    }

    &:hover {
      background-color: $white-hover;
    }

    &--expanded {
      background-image: url("/images/minus.png");
    }
  }

  &__question,
  &__answer {
    display: block;
    clear: both;
  }

  &__answer {
    color: $primary;
    display: none;
    margin-top: 10px;
    display: none;
    margin-right: 50px;
  }
}

// Footer
.footer {
  background-color: $primary;

  &__form,
  &__info {
    @include span(4);
  }

  &__info {
    @include push(4);

    &__logo {
      margin-bottom: 3rem;
    }

    &__partners {
      background-color: $white;
      display: block;
      border-radius: 0.4rem;
      float: left;
      padding: 1rem;
      margin-top: 7rem;

      a {
        display: block;
        text-align: center;
        padding: 1rem;

        img {
          padding: 1rem;
          float: left;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding-left: 2rem;
    padding-right: 2rem;

    &__form,
    &__info {
      @include span(12);
      @include push(0);
    }

    &__form {
      margin-bottom: 8rem;
    }
  }
}

// Form
.form {
  &__title {
    color: $white;
    display: block;
    clear: both;
    font-weight: bold;
    margin: 2rem 0 1rem;
  }

  &__radio-buttons {
    clear: both;
  }
}

.textfield {
  border-radius: 0.4rem;
  margin-bottom: 1rem;

  .input {
    &--text {
      border-radius: 0.4rem;
      border: none;
      padding: 3rem 2rem 1.5rem;
      margin-bottom: 0.1rem;
    }
  }

  &__label {
    padding-top: 1rem;
    padding-left: 2rem;
  }
}

.radio {
  &::before {
    border: none;
    padding: 0.2rem;
  }

  &__label {
    color: $white;
    font-size: 1.8rem;
  }
}

// Typo
p {
  font-size: 1.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h2 {
  font-size: 4.4rem;
  line-height: 5.4rem;
  font-weight: 700;
}

h3 {
  font-size: 3rem;
  line-height: 4.2rem;
}

.txt {
  &--white {
    color: $white;
  }

  &--bold {
    font-weight: 700;
  }

  &--subtitle {
    font-size: 3.1rem;
    color: #0057d7;
    font-weight: 600;
    line-height: 3.3rem;
    letter-spacing: -0.03rem;
    display: block;
    margin-bottom: 3.5rem;

    @media only screen and (max-width: 960px) {
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }
}

.lnk {
  text-decoration: none;

  &:link {
    color: $black;
  }

  &:hover {
    color: $primary;
    cursor: pointer;
  }

  &:visited {
    color: inherit;
  }

  &--white {
    color: $white !important;

    &:visited {
      color: $white;
    }
  }

  &--nav {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff !important;
    padding: 2.8rem 1rem;
    display: block;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: 1015px) {
      font-size: 1.5rem;
    }
  }

  &--logo-blue {
    display: none;
  }
}

.selected {
  color: $primary;
}

// Video overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  height: 100%;
  width: 100%;
  display: none;

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    font-size: 20px;
    font-size: 60px;
    cursor: pointer;
    color: #afafaf;
    z-index: 99999;

    &:hover {
      color: #000;
    }
  }

  &__wrapper {
    display: table;
    position: absolute;
    padding: 70px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__video {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 100%;

    iframe {
      max-width: 1280px;
      max-height: 720px;
      width: 100%;
      height: 100%;
    }
  }
}

// Testimonials

.testimonials {
  background-color: $primary;
  padding: 100px 0;

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  span {
    color: $white;
  }

  .carousel-inner li {
    padding-left: 14rem;
    background: transparent url(/images/quote.png) no-repeat 3rem top;
    padding-top: 3rem;
    padding-right: 10rem;
  }

  .carousel-testimonial {
    font-size: 2.4rem;
    line-height: 1.5;
  }

  .carousel-firma {
    font-size: 2.4rem;
    display: block;
    clear: both;
    margin-top: 4rem;
  }
}

#prize {
  padding: 100px;

  .left {
    float: left;
    width: 35%;

    img {
      float: left;
      margin-top: 30%;
    }
  }

  .right {
    float: left;
    margin-left: 5%;
    width: 60%;
    padding: 1em 0;
    text-align: left;
  }
}

.carousel-white-theme {
  .carousel-inner li {
    padding-left: 10rem;
    padding-top: 3rem;
    padding-right: 10rem;
  }

  .unslider-nav {
    ol {
      li {
        background: #ececec;

        &.unslider-active {
          background: $blue-text;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  #prize {
    padding: 2rem 0;

    .carousel-inner li {
      padding: 2rem;

      .left,
      .right {
        width: 100%;
        display: block;
        float: none;
        clear: both;
        margin: 0;
      }

      .left {
        margin-bottom: 2em;

        img {
          float: none;
          margin-top: auto;
        }
      }

      .right {
        h3 {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
    }
  }

  .testimonials .carousel-inner li {
    padding-left: 2rem;
    background: transparent url(/images/quote.png) no-repeat 3rem top;
    padding-top: 3rem;
    padding-right: 2rem;
    background-size: 2rem;
  }

  .testimonials .carousel-testimonial,
  .testimonials .carousel-firma {
    font-size: 2rem;
  }
}

// Buttons

.btn {
  font-weight: 700;
  border-radius: 0.4rem;

  &--small {
    font-size: 1.4rem;
    padding: 0 1rem;
    line-height: 3.5rem;
    height: auto;
  }

  &--register {
    font-size: 1.8rem;
    background-color: #0065fa;
    padding: 0 2rem;
    margin-left: 1.5rem;
  }

  &--white {
    background-color: $white;
    color: $primary;

    &:focus {
      background-color: $white;
      color: $primary;
    }
  }

  &--submit {
    margin-top: 2rem;
  }

  &--expand {
    padding-left: 3.7rem;
    background-image: url("/images/plus.png");
    background-repeat: no-repeat;
    background-position: left 1rem center;

    &--minus {
      background-image: url("/images/minus.png");
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.impressum {
  padding: 50px;

  p {
    padding-bottom: 0;
  }

  ul li {
    display: block;
  }
}

#eoi-success {
  border-radius: 0.4rem;
  display: none;
  padding: 1em;
  color: #154402;
  background: #cfffad;
  font-weight: bold;
  margin: 1em 0;

  p {
    margin: 0;
    padding: 0;
  }
}

#prize {
  .section__title {
    color: #000;
  }

  .section__body {
    color: #000 !important;
  }

  .section--prize-1 {
    @media screen and (max-width: 960px) {
      background-image: none;
      padding-top: 6rem !important;
    }
  }
}

#tos_accepted {
  display: inline-block;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

.subsection--with-image {
  display: flex;
  flex-direction: row;
  background-color: $bg-blue;
  color: #fff !important;
  padding: 2rem;
  border-radius: 0.5rem;

  h4 {
    margin-top: 0;
  }

  .subsection-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
  }

  .subsection-image--extra-padding {
    padding: 4rem;
  }

  .subsection-text {
    display: block;
    align-items: center;
    justify-content: center;
    width: 66%;

    .btn {
      color: $bg-blue !important;
      float: right;
    }
  }

  @media only screen and (max-width: 960px) {
    .subsection-image {
      display: none;
    }

    .subsection-text {
      width: 100%;
    }
  }
}
