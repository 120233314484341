// Typo

$base-font:       'Source Sans Pro','Avenir', 'Avenir Next','Helvetica Neue','Segoe UI', 'Verdana', sans-serif;

// Colors
$primary            : #1A71EF;
$black              : #000000;
$yellow             : #FFD44C;
$blue               : #b3def6;
$soft-blue          : #3788FF;
$red                : #cec8f6;
$soft-red           : #FF7469;

$light-blue         : #F9FBFF;
$dark-blue          : #293446;
$bg-blue            : #3788ff;
$yellow-text        : #985200;
$blue-text          : #3677D2;
$gray-text          : #3A3A3A;

$white-hover        : #fcfcff;
$white-transsparent : rgba(255,255,255,0.7);