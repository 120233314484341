/* line 1, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
* {
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 11, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%; }
  @media screen and (min-width: 960px) {
    /* line 11, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
    html {
      font-size: 78.125%; } }

/* line 21, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
body {
  box-sizing: border-box;
  color: #3c3c3d;
  font-family: "Source Sans Pro", "Avenir", "Avenir Next", "Helvetica Neue", "Segoe UI", "Verdana", sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-height: 100%;
  position: relative; }

/* line 31, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", "Avenir", "Avenir Next", "Helvetica Neue", "Segoe UI", "Verdana", sans-serif;
  font-weight: 500; }

/* line 36, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h1, h2, h3 {
  line-height: 4.8rem;
  padding: .8rem 0 1.6rem; }

/* line 41, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h4, h5, h6 {
  padding: 2.4rem 0; }

/* line 45, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h1 {
  font-size: 3.6rem; }

/* line 49, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h2 {
  font-size: 3.2rem; }

/* line 53, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h3 {
  font-size: 2.8rem; }

/* line 57, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h4 {
  font-size: 2.0rem; }

/* line 61, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h5 {
  font-size: 1.8rem; }

/* line 65, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
h6 {
  font-size: 1.6rem; }

/* line 69, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
p {
  padding-bottom: 2.4rem; }

/* line 73, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
form {
  padding: 2.4rem 0; }

/* line 77, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
pre,
code,
samp,
kbd {
  background-color: #efeff0;
  font-size: 1.2rem;
  color: #1065e0;
  font-family: "Consolas", "Monaco", "Courier New", monospace;
  line-height: 1; }

/* line 88, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
code,
samp,
kbd {
  border-radius: 2px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: .4rem .4rem .2rem; }

/* line 97, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
pre {
  border-radius: 2px;
  line-height: 2.4rem;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1.2rem 1rem;
  margin: 0 0 2.4rem; }
  /* line 105, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  pre code,
  pre samp,
  pre kbd {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0; }

/* line 115, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
a:link {
  color: #4076D4; }

/* line 119, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
a:visited {
  color: #9140D4; }

/* line 123, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
a:hover,
a:focus {
  color: #2e68cc; }

/* line 128, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
a:active {
  color: #D44076; }

/* line 132, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
img,
video {
  max-width: 100%;
  display: table; }

/* line 138, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
hr {
  height: 4.8rem;
  margin-bottom: 2.4rem;
  position: relative; }
  /* line 143, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  hr:before {
    background-color: #cdcdce;
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%; }

/* line 153, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
ol,
ul {
  margin-left: 2rem;
  padding: 0 0 2.4rem; }

/* line 159, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
ol {
  list-style-type: decimal; }

/* line 163, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
ol ol {
  padding-bottom: 0; }

/* line 167, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
ul {
  list-style-type: disc; }

/* line 171, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
ul ul {
  padding-bottom: 0; }

/* line 175, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
dt {
  font-style: italic; }

/* line 178, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
dd {
  text-indent: 2rem; }

/* line 182, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
blockquote {
  margin: 0 0 2.4rem 1.2rem;
  border-left: 0.3rem solid #cdcdce;
  padding: 2.4rem 1.2rem; }
  /* line 187, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  blockquote p:last-child {
    padding-bottom: 0; }

/* line 193, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
figure {
  margin: 0 auto;
  padding-bottom: 2.4rem; }
  /* line 197, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  figure img {
    display: table;
    margin: 0 auto; }

/* line 203, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
small,
sub,
sup {
  font-size: 1.2rem;
  line-height: 1; }

/* line 210, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
caption,
figcaption {
  font-size: 1.2rem;
  font-style: italic;
  text-align: center; }

/* line 217, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
em,
cite,
i {
  font-style: italic; }

/* line 223, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
strong,
var,
b {
  font-weight: bold; }

/* line 230, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
q:before {
  content: "'"; }

/* line 234, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
q:after {
  content: "'"; }

/* line 238, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
q > q {
  font-style: italic; }

/* line 243, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
dfn,
abbr {
  border-bottom: 0.1rem dotted #5a5a5b;
  cursor: default; }

/* line 249, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
table {
  border-radius: 2px;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 2.4rem;
  width: 100%; }
  /* line 256, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  table th {
    font-weight: 500;
    text-align: left; }
  /* line 261, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
  table th, table td {
    padding: 0 .6rem; }

/* line 266, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
label {
  color: #7d7d7e; }

/* line 270, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
input,
label,
select,
button,
textarea {
  display: block;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

/* remove yellow from chrome auto-fill background */
/* line 283, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_base.scss */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: #3c3c3d;
  -webkit-text-fill-color: #3c3c3d;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; }

/*

Utilities

These utility classes have one or two CSS properties that are meant to save you from creating several identical classes to do the same thing, like just centering some text.

Markup:
<div class="{{modifier_class}}" style="background-color: #ddd">
  Example Text
</div>

.disabled                 - Disable an Element
.clearfix                - Clear floats to keep the parent container in shape
.f-none                  - float: none;
.f-left                  - float: left;
.f-right                  - float: right;
.ta-left                 - text-align: left;
.ta-center                - text-align: center;
.ta-right                - text-align: right;

Styleguide 4.0

*/
/* line 25, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.disabled, input[disabled],
input[disabled] + *,
button[disabled],
button[disabled] + *,
textarea[disabled],
textarea[disabled] + *,
select[disabled],
select[disabled] + * {
  cursor: default;
  opacity: .5;
  pointer-events: none; }

/* line 31, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.f-none {
  float: none; }

/* line 35, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.f-left {
  float: left; }

/* line 39, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.f-right {
  float: right; }

/* line 43, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.clearfix {
  clear: both;
  overflow: hidden; }

/* line 48, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.ta-left {
  text-align: left; }

/* line 52, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.ta-center {
  text-align: center; }

/* line 56, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.ta-right {
  text-align: right; }

/*

Type Utilities

These are specific typography related utilities

Markup:
<div class="type {{modifier_class}}">
  The quick brown fox jumps over the lazy dog.
</div>

.fs-small            - Smaller than the default font size
.fs-base              - Exactly the default font size
.fs-large            - Larger than the default font size
.fw-light            - Changes the type to be 300 weight
.fw-normal           - Changes the type to be 400 weight
.fw-semibold         - Changes the type to be 500 weight
.fw-bold             - Changes the type to be 600 weight

Styleguide 4.1

*/
/* line 84, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fs-small {
  font-size: 1.2rem; }

/* line 88, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fs-base {
  font-size: 1.6rem; }

/* line 92, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fs-large {
  font-size: 2rem; }

/* line 96, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fw-light {
  font-weight: 300; }

/* line 100, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fw-normal {
  font-weight: 400; }

/* line 104, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fw-semibold {
  font-weight: 500; }

/* line 108, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/support/_utilities.scss */
.fw-bold {
  font-weight: 600; }

/*

Buttons

Buttons and their variations

Markup:
<a href="#" class="btn {{modifier_class}}">Button</a>

.btn--secondary       - A link that's sized like a button
.btn--link            - A link that's sized like a button

Styleguide 2.0

*/
/* line 17, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
.btn, [type=button],
[type=submit],
button {
  background-color: #1A71EF;
  border: none;
  border-radius: .2rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  height: 4.8rem;
  line-height: 4.8rem;
  max-width: 100%;
  transition-property: background-color;
  transition-duration: 200ms;
  margin: 0 auto;
  padding: 0 3rem;
  text-align: center;
  user-select: none;
  width: auto; }
  /* line 36, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn:link, [type=button]:link,
  [type=submit]:link,
  button:link, .btn:visited, [type=button]:visited,
  [type=submit]:visited,
  button:visited {
    color: #ffffff;
    text-decoration: none; }
  /* line 42, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn:focus, [type=button]:focus,
  [type=submit]:focus,
  button:focus, .btn:hover, [type=button]:hover,
  [type=submit]:hover,
  button:hover {
    background-color: #1065e0;
    color: #ffffff; }
  /* line 48, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn:focus, [type=button]:focus,
  [type=submit]:focus,
  button:focus {
    outline: 0.1rem dotted #7d7d7e; }
  /* line 52, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn:active, [type=button]:active,
  [type=submit]:active,
  button:active {
    background-color: #1A71EF; }

/* line 57, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
.btn--secondary {
  background-color: #efeff0;
  color: #3c3c3d; }
  /* line 61, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--secondary:link, .btn--secondary:visited {
    color: #3c3c3d; }
  /* line 66, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--secondary:focus, .btn--secondary:hover {
    background-color: #dededf;
    color: #3c3c3d; }
  /* line 72, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--secondary:active {
    color: #7d7d7e; }

/* line 77, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
.btn--link {
  background-color: transparent;
  color: #4076D4;
  text-decoration: underline; }
  /* line 82, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--link:link {
    color: #4076D4; }
  /* line 86, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--link:visited {
    color: #9140D4; }
  /* line 90, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--link:focus, .btn--link:hover {
    color: #2e68cc;
    background-color: transparent; }
  /* line 96, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_buttons.scss */
  .btn--link:active {
    color: #D44076;
    background-color: transparent; }

/*

Forms

Markup:
<form>
  <label class="textfield">
    <input type="text" />
    <span class="textfield__label">Name</span>
  </label>
  <label class="radio">
    <input type="radio" name="example" />
    <span class="radio__label">Desktop</span>
  </label>
  <label class="radio">
    <input type="radio" name="example" />
    <span class="radio__label">Mobile</span>
  </label>
  <label class="select">
    <select name="select">
      <option disabled="disabled" selected="selected">Flavors</option>
      <option>Chocolate</option>
    </select>
    <span class="select__label" for="select">Favorite Ice Cream</span>
  </label>
  <label class="textfield">
    <textarea></textarea>
    <span class="textfield__label">Comments</span>
  </label>
  <label class="checkbox">
    <input type="checkbox" />
    <span class="checkbox__label">I Lied</span>
  </label>
  <br/>
  <input type="submit" value="Cross My Heart" />
</form>

Styleguide 3.0

*/
/* line 66, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio,
.checkbox {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 2.4rem;
  padding: 0 1rem 0 3rem;
  position: relative; }
  /* line 74, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
  .radio:before,
  .checkbox:before {
    background-color: #fff;
    border: 2px solid #7d7d7e;
    content: '';
    height: 16px;
    left: 0;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 16px; }

/* line 87, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.checkbox input[type=checkbox],
.radio input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: -1; }

/* line 94, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio__label,
.checkbox__label {
  color: #7d7d7e;
  font-size: 1.2rem;
  line-height: 1; }

/* line 101, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio__label:after,
.checkbox__label:after {
  border: 1px solid transparent;
  content: '';
  height: 18px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 18px; }

/* line 113, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio input[type=radio]:focus + .radio__label:after,
.checkbox input[type=checkbox]:focus + .checkbox__label:after {
  border-color: #1A71EF;
  box-shadow: 0 1px 2px 1px #dededf inset; }

/* line 125, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.textfield,
.select {
  background-color: #ffffff;
  display: block;
  margin-top: -2px;
  margin-bottom: 2.4rem;
  position: relative; }

/* line 134, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.textfield__label,
.select__label {
  font-size: 1.2rem;
  position: absolute;
  left: 1px;
  line-height: 1;
  padding: .5rem;
  right: 1px;
  top: 1px; }

/* line 145, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.textfield input,
.textfield textarea,
.select select {
  background-color: transparent;
  border: 1px solid #7d7d7e;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 1.8rem .5rem .6rem;
  position: relative;
  width: 100%;
  z-index: 100; }

/* line 158, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.textfield *:focus,
.select select:focus {
  border-color: #1A71EF;
  box-shadow: 0 1px 2px 0 #dededf inset;
  outline: none; }

/*

Radio

Markup:
<label class="radio {{modifier_class}}">
  <input type="radio" />
  <span class="radio__label">Radio</span>
</label>

Styleguide 3.1

*/
/* line 179, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio:before,
.radio__label:after {
  border-radius: 20px; }

/* line 184, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.radio input[type=radio]:checked + .radio__label:before {
  background-color: #1065e0;
  border-radius: 20px;
  content: '';
  height: 10px;
  left: 5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px; }

/*

Checkbox

Markup:
<label class="checkbox {{modifier_class}}">
  <input type="checkbox" />
  <span class="checkbox__label">Checkbox</span>
</label>

Styleguide 3.2

*/
/* line 210, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.checkbox:before,
.checkbox__label:after {
  border-radius: 2px; }

/* line 215, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.checkbox input[type=checkbox]:checked + .checkbox__label:after {
  color: #1065e0;
  content: '\2714';
  font-size: 14px;
  line-height: 18px;
  text-align: center; }

/*

Input and text areas

Markup:
<label class="textfield {{modifier_class}}">
  <input type="text" />
  <span class="textfield__label">Input</span>
</label>
<label class="textfield {{modifier_class}}">
  <textarea></textarea>
  <span class="textfield__label">Textarea</span>
</label>

Styleguide 3.3

*/
/*

Select

Markup:
<label class="select {{modifier_class}}">
  <select>
    <option disabled="disabled" selected="selected">Select</option>
    <option>Option 1</option>
    <option>Option 2</option>
    <option>Option 3</option>
  </select>
  <span class="select__label">Select Label</span>
</label>

Styleguide 3.4

*/
/* line 261, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.select:after {
  content: '';
  border-left: .6rem solid transparent;
  border-right: .6rem solid transparent;
  border-top: 0.6rem solid #7d7d7e;
  height: 0;
  margin-top: -.5rem;
  position: absolute;
  right: 1rem;
  bottom: 1.3rem;
  width: 0; }

/* line 275, /home/digi/code/static/junite-landing-page/bower_components/cutestrap/dist/scss/components/_forms.scss */
.select option[disabled] {
  background-color: #efeff0;
  color: #5a5a5b; }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

/* line 37, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
* {
  box-sizing: border-box; }

/* line 41, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
html,
body {
  width: 100%;
  height: 100%; }

@media screen and (min-width: 960px) {
  /* line 47, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  html {
    font-size: 62.5%; } }

/* line 53, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
body {
  color: #000000;
  background-color: #3788ff;
  top: 0;
  z-index: 1; }

/* line 60, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  /* line 65, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  ul li {
    display: inline-block; }

/* line 70, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
a,
button {
  outline: 0 !important; }

/* line 77, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.inline-block {
  display: inline-block; }

/* line 81, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.float-left {
  float: left; }

/* line 85, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.clear {
  clear: both; }

/* line 91, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.one-in-small {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem; }

/* line 99, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 120rem;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem; }
  /* line 12, /home/digi/code/static/junite-landing-page/bower_components/susy/sass/susy/output/support/_clearfix.scss */
  .wrapper:after {
    content: " ";
    display: block;
    clear: both; }
  @media only screen and (max-width: 960px) {
    /* line 99, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper {
      padding-left: 2rem;
      padding-right: 2rem; } }
  /* line 112, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .wrapper--nav {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 0rem 3rem;
    height: 80px;
    box-sizing: border-box;
    z-index: 9;
    background-color: #1A71EF;
    padding-left: 0;
    padding-right: 0; }
    @media only screen and (max-width: 960px) {
      /* line 112, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .wrapper--nav {
        padding: 0;
        height: 5rem;
        text-align: center; } }
  /* line 131, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .wrapper--fixed {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.06);
    z-index: 99; }
    /* line 137, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper--fixed .lnk--logo {
      display: none; }
    /* line 141, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper--fixed .lnk--logo-blue {
      display: block; }
      /* line 144, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .wrapper--fixed .lnk--logo-blue .lnk--logo {
        display: block; }
    /* line 149, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper--fixed .lnk--nav {
      color: #313131 !important; }
    @media only screen and (max-width: 960px) {
      /* line 131, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .wrapper--fixed {
        display: none !important; } }
  /* line 158, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .wrapper--header {
    position: relative;
    height: 100%;
    display: table;
    padding-bottom: 0;
    z-index: 8; }
  /* line 166, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .wrapper--section {
    clear: both;
    padding: 13rem 0;
    text-align: left;
    display: inline-block; }
    @media only screen and (max-width: 960px) {
      /* line 166, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .wrapper--section {
        padding: 6rem 2rem; } }
    /* line 176, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper--section--faq {
      padding-top: 0; }
    /* line 180, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .wrapper--section--desc {
      padding: 8rem 2rem; }
  /* line 185, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .wrapper--footer {
    padding: 6rem 0; }

/* line 193, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.nav__logo {
  padding-top: 0;
  float: left;
  padding-bottom: 0;
  margin-top: 1.4rem; }
  @media only screen and (max-width: 960px) {
    /* line 200, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .nav__logo a {
      width: 100%;
      text-align: center;
      margin-top: 1rem; }
      /* line 205, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .nav__logo a img {
        width: 100px; } }

/* line 212, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.nav__links {
  float: right;
  display: none; }
  @media only screen and (min-width: 960px) {
    /* line 212, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .nav__links {
      display: block; } }

/* line 224, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.header {
  background: #3788FF url(/images/bg-shapes.png) center 24rem no-repeat;
  height: 87rem; }
  @media only screen and (max-width: 960px) {
    /* line 224, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header {
      height: auto;
      background-size: contain;
      background-position: center bottom; } }
  /* line 235, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__stamp {
    position: absolute;
    bottom: 30px;
    right: 342px;
    z-index: 9; }
    @media only screen and (max-width: 960px) {
      /* line 235, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__stamp {
        display: none; } }
  /* line 246, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7.6rem;
    margin-top: -7rem;
    background: transparent url(/images/play-red.png) no-repeat center center;
    width: 17rem;
    height: 17rem;
    cursor: pointer;
    z-index: 9;
    animation-name: pulse_animation;
    animation-duration: 5000ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }
    @media only screen and (max-width: 960px) {
      /* line 246, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__play {
        top: 32rem;
        margin-right: -8rem;
        right: 50%; } }
    /* line 264, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__play:hover {
      filter: saturate(2); }
  /* line 275, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__sub {
    background: transparent url(/images/bg-waves.png) top left repeat-x;
    width: 100%;
    margin-top: -20rem;
    display: block;
    float: left; }
    @media only screen and (max-width: 960px) {
      /* line 275, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__sub {
        background: transparent;
        margin-top: -7rem; } }
    /* line 287, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__sub--wrapper {
      background-color: #ffffff;
      margin-top: 4rem;
      padding-top: 18rem;
      padding-bottom: 3rem;
      text-align: center;
      position: relative; }
      @media only screen and (max-width: 960px) {
        /* line 287, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .header__sub--wrapper {
          padding-top: 6rem;
          height: 20rem; } }
  /* line 302, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__picture {
    display: block;
    text-align: center;
    width: 100%;
    display: inline-block; }
    /* line 308, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__picture img {
      display: inline-block; }
  /* line 313, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__claim {
    margin-top: 11.5rem;
    float: none; }
    @media (min-width: 30rem) {
      /* line 313, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__claim {
        width: 100%;
        text-align: center;
        color: #fff;
        float: none;
        margin-left: 0; }
        /* line 321, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .header__claim h2 {
          font-size: 5.5rem;
          letter-spacing: -0.7px; } }
    @media only screen and (max-width: 960px) {
      /* line 313, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__claim {
        margin-top: 8rem;
        display: block; }
        /* line 334, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .header__claim h2 {
          font-size: 2.5rem;
          color: #ffffff;
          line-height: 1.4; } }
  /* line 342, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__note {
    margin: 2rem auto 0; }
  /* line 346, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__logos--left, .header__logos--right {
    position: absolute;
    bottom: 3rem; }
    @media only screen and (max-width: 1200px) {
      /* line 352, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__logos--left img, .header__logos--right img {
        max-width: 150px;
        max-height: 50px; } }
    @media only screen and (max-width: 960px) {
      /* line 346, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__logos--left, .header__logos--right {
        display: none; } }
  /* line 363, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__logos--left {
    left: 3rem; }
    /* line 366, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__logos--left img {
      display: inline-block;
      float: left;
      margin-right: 3rem; }
  /* line 373, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .header__logos--right {
    right: 3rem; }
    /* line 376, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__logos--right img {
      display: inline-block;
      float: right;
      margin-right: 3rem; }
  @media only screen and (max-width: 1480px) {
    /* line 383, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .header__logos {
      clear: both;
      margin-top: 100px; }
      /* line 388, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .header__logos:after {
        content: "";
        display: table;
        clear: both; } }

/* line 397, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.features {
  background-color: #FF7469;
  margin-bottom: 6rem;
  width: 100%;
  display: block;
  padding: 0rem 0 1rem; }
  @media only screen and (max-width: 960px) {
    /* line 397, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .features {
      margin-bottom: 0rem; } }
  /* line 409, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .features__item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding: 10rem 0 1rem; }
    @media only screen and (max-width: 480px) {
      /* line 409, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .features__item {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        float: left;
        padding-left: 0.83333%;
        padding-right: 0.83333%;
        height: 16rem; } }
    /* line 423, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .features__item--kids {
      background: transparent url(/images/features-kids.png) no-repeat center 3rem; }
    /* line 427, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .features__item--age {
      background: transparent url(/images/features-age.png) no-repeat center 3.9rem; }
    /* line 431, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .features__item--time {
      background: transparent url(/images/features-time.png) no-repeat center 3.9rem; }
    /* line 435, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .features__item--place {
      background: transparent url(/images/features-place.png) no-repeat center 3rem; }

/* line 443, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.section {
  text-align: center; }
  /* line 446, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--desc {
    background-color: #ffffff;
    margin: 13rem 0; }
  /* line 451, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--left {
    background-position: right 15% center;
    background-repeat: no-repeat; }
  /* line 456, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--right {
    background-position: left 15% center;
    background-repeat: no-repeat; }
  @media only screen and (max-width: 960px) {
    /* line 463, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--left, .section--right {
      background-position: center top 40px;
      background-size: 100px;
      padding-top: 15rem; } }
  /* line 472, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--three__title {
    text-align: center;
    margin-bottom: 8rem;
    font-weight: 400;
    color: #000000 !important; }
  /* line 479, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--three__item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33333%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    text-align: center;
    background-size: 50%;
    padding-top: 13rem; }
    /* line 484, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item--small-1 {
      background: transparent url(/images/small-1.png) no-repeat center top; }
    /* line 488, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item--small-2 {
      background: transparent url(/images/small-2.png) no-repeat center top 0.3rem; }
    /* line 492, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item--small-3 {
      background: transparent url(/images/small-3.png) no-repeat center top 1.2rem; }
    /* line 496, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item__title {
      color: #1A71EF;
      font-size: 1.8rem; }
    /* line 501, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item__body {
      padding: 0 15%;
      color: #3A3A3A;
      font-size: 1.6rem; }
  @media only screen and (max-width: 960px) {
    /* line 509, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--three__item {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 4rem; }
      /* line 515, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .section--three__item__body {
        padding: 0 1rem; } }
  /* line 522, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--left--text {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 41.66667%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%; }
  /* line 526, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--right--text {
    margin-left: 58.33333%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 41.66667%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%; }
  @media only screen and (max-width: 960px) {
    /* line 532, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--left--text {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%; }
    /* line 536, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section--right--text {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      margin-left: 0%; } }
  /* line 542, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--big-1 {
    background-image: url(/images/big-1.png); }
  /* line 546, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--prize-1 {
    background-image: url(/images/klicksafe-prize-lg.png); }
  /* line 550, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--big-2 {
    background-image: url(/images/big-2.png); }
  /* line 554, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section--big-3 {
    background-image: url(/images/big-3.png); }
  /* line 558, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section__title {
    color: #ffffff; }
    /* line 561, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section__title--faq {
      background: transparent url("/images/faq-face.png") no-repeat left center;
      padding: 3rem 0 6rem 12rem; }
      /* line 565, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .section__title--faq__speech-bubble {
        background-color: #ffffff;
        font-weight: normal;
        padding: 2.5rem 3rem;
        font-size: 2.4rem;
        border-radius: 3rem;
        border-bottom-left-radius: 1.8rem;
        position: relative;
        color: #000000; }
        /* line 575, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .section__title--faq__speech-bubble::after {
          content: "";
          position: absolute;
          border-style: solid;
          border-width: 1rem 1rem 1rem 0;
          border-color: transparent #ffffff;
          display: block;
          width: 0;
          z-index: 1;
          left: -0.8rem;
          top: 3rem; }
      @media only screen and (max-width: 960px) {
        /* line 590, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .section__title--faq__speech-bubble {
          font-size: 1.8rem;
          padding: 2.5rem 2rem; } }
  /* line 598, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section__body {
    color: #ffffff; }
  /* line 602, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .section__more {
    display: none;
    background-color: #ffffff;
    margin-top: -13rem;
    padding: 8rem 2rem 5rem; }
    /* line 608, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section__more--title {
      padding-bottom: 0.5rem; }
    /* line 612, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .section__more__item {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 41.66667%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      padding-right: 2%; }
      /* line 616, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .section__more__item--two {
        margin-left: 16.66667%; }
    @media only screen and (max-width: 960px) {
      /* line 602, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .section__more {
        margin-top: -6rem; }
        /* line 624, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .section__more .wrapper {
          padding: 0; }
        /* line 628, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .section__more__item {
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 100%;
          float: left;
          padding-left: 0.83333%;
          padding-right: 0.83333%;
          padding-right: 0;
          margin-bottom: 4rem; }
          /* line 633, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
          .section__more__item--two {
            margin-left: 0%; } }

/* line 642, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.faq__item {
  cursor: pointer;
  width: 100%;
  padding: 2rem 3rem;
  margin-bottom: 0.3rem;
  box-sizing: border-box;
  background: #ffffff url("/images/plus.png") no-repeat right 2rem center; }
  /* line 650, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .faq__item:first-child {
    border-radius: 0.4rem 0.4rem 0 0; }
  /* line 654, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .faq__item:last-child {
    border-radius: 0 0 0.4rem 0.4rem; }
  /* line 658, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .faq__item:hover {
    background-color: #fcfcff; }
  /* line 662, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .faq__item--expanded {
    background-image: url("/images/minus.png"); }

/* line 667, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.faq__question, .faq__answer {
  display: block;
  clear: both; }

/* line 673, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.faq__answer {
  color: #1A71EF;
  display: none;
  margin-top: 10px;
  display: none;
  margin-right: 50px; }

/* line 683, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.footer {
  background-color: #1A71EF; }
  /* line 686, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .footer__form, .footer__info {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33333%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%; }
  /* line 691, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .footer__info {
    margin-left: 33.33333%; }
    /* line 694, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .footer__info__logo {
      margin-bottom: 3rem; }
    /* line 698, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .footer__info__partners {
      background-color: #ffffff;
      display: block;
      border-radius: 0.4rem;
      float: left;
      padding: 1rem;
      margin-top: 7rem; }
      /* line 706, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .footer__info__partners a {
        display: block;
        text-align: center;
        padding: 1rem; }
        /* line 711, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        .footer__info__partners a img {
          padding: 1rem;
          float: left; }
  @media only screen and (max-width: 960px) {
    /* line 683, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .footer {
      padding-left: 2rem;
      padding-right: 2rem; }
      /* line 723, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .footer__form, .footer__info {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        float: left;
        padding-left: 0.83333%;
        padding-right: 0.83333%;
        margin-left: 0%; }
      /* line 729, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .footer__form {
        margin-bottom: 8rem; } }

/* line 737, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.form__title {
  color: #ffffff;
  display: block;
  clear: both;
  font-weight: bold;
  margin: 2rem 0 1rem; }

/* line 745, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.form__radio-buttons {
  clear: both; }

/* line 750, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.textfield {
  border-radius: 0.4rem;
  margin-bottom: 1rem; }
  /* line 755, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .textfield .input--text {
    border-radius: 0.4rem;
    border: none;
    padding: 3rem 2rem 1.5rem;
    margin-bottom: 0.1rem; }
  /* line 763, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .textfield__label {
    padding-top: 1rem;
    padding-left: 2rem; }

/* line 770, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.radio::before {
  border: none;
  padding: 0.2rem; }

/* line 775, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.radio__label {
  color: #ffffff;
  font-size: 1.8rem; }

/* line 782, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
p {
  font-size: 1.8rem; }

/* line 786, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600; }

/* line 795, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
h2 {
  font-size: 4.4rem;
  line-height: 5.4rem;
  font-weight: 700; }

/* line 801, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
h3 {
  font-size: 3rem;
  line-height: 4.2rem; }

/* line 807, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.txt--white {
  color: #ffffff; }

/* line 811, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.txt--bold {
  font-weight: 700; }

/* line 815, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.txt--subtitle {
  font-size: 3.1rem;
  color: #0057d7;
  font-weight: 600;
  line-height: 3.3rem;
  letter-spacing: -0.03rem;
  display: block;
  margin-bottom: 3.5rem; }
  @media only screen and (max-width: 960px) {
    /* line 815, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .txt--subtitle {
      font-size: 1.8rem;
      line-height: 1.5; } }

/* line 831, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.lnk {
  text-decoration: none; }
  /* line 834, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk:link {
    color: #000000; }
  /* line 838, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk:hover {
    color: #1A71EF;
    cursor: pointer; }
  /* line 843, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk:visited {
    color: inherit; }
  /* line 847, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk--white {
    color: #ffffff !important; }
    /* line 850, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .lnk--white:visited {
      color: #ffffff; }
  /* line 855, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk--nav {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff !important;
    padding: 2.8rem 1rem;
    display: block; }
    /* line 862, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .lnk--nav:hover {
      cursor: pointer; }
    @media only screen and (max-width: 1015px) {
      /* line 855, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      .lnk--nav {
        font-size: 1.5rem; } }
  /* line 871, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .lnk--logo-blue {
    display: none; }

/* line 876, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.selected {
  color: #1A71EF; }

/* line 881, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  height: 100%;
  width: 100%;
  display: none; }
  /* line 893, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .overlay__close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    font-size: 20px;
    font-size: 60px;
    cursor: pointer;
    color: #afafaf;
    z-index: 99999; }
    /* line 904, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .overlay__close:hover {
      color: #000; }
  /* line 909, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .overlay__wrapper {
    display: table;
    position: absolute;
    padding: 70px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }
  /* line 921, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .overlay__video {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 100%; }
    /* line 927, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .overlay__video iframe {
      max-width: 1280px;
      max-height: 720px;
      width: 100%;
      height: 100%; }

/* line 938, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.testimonials {
  background-color: #1A71EF;
  padding: 100px 0; }
  /* line 942, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .wrapper {
    padding-left: 0;
    padding-right: 0; }
  /* line 947, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials span {
    color: #ffffff; }
  /* line 951, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .carousel-inner li {
    padding-left: 14rem;
    background: transparent url(/images/quote.png) no-repeat 3rem top;
    padding-top: 3rem;
    padding-right: 10rem; }
  /* line 958, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .carousel-testimonial {
    font-size: 2.4rem;
    line-height: 1.5; }
  /* line 963, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .carousel-firma {
    font-size: 2.4rem;
    display: block;
    clear: both;
    margin-top: 4rem; }

/* line 971, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
#prize {
  padding: 100px; }
  /* line 974, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  #prize .left {
    float: left;
    width: 35%; }
    /* line 978, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    #prize .left img {
      float: left;
      margin-top: 30%; }
  /* line 984, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  #prize .right {
    float: left;
    margin-left: 5%;
    width: 60%;
    padding: 1em 0;
    text-align: left; }

/* line 994, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.carousel-white-theme .carousel-inner li {
  padding-left: 10rem;
  padding-top: 3rem;
  padding-right: 10rem; }

/* line 1002, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.carousel-white-theme .unslider-nav ol li {
  background: #ececec; }
  /* line 1005, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .carousel-white-theme .unslider-nav ol li.unslider-active {
    background: #3677D2; }

@media only screen and (max-width: 960px) {
  /* line 1014, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  #prize {
    padding: 2rem 0; }
    /* line 1017, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    #prize .carousel-inner li {
      padding: 2rem; }
      /* line 1020, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      #prize .carousel-inner li .left,
      #prize .carousel-inner li .right {
        width: 100%;
        display: block;
        float: none;
        clear: both;
        margin: 0; }
      /* line 1029, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      #prize .carousel-inner li .left {
        margin-bottom: 2em; }
        /* line 1032, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
        #prize .carousel-inner li .left img {
          float: none;
          margin-top: auto; }
      /* line 1039, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
      #prize .carousel-inner li .right h3 {
        font-size: 1.2em;
        font-weight: bold; }
  /* line 1047, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .carousel-inner li {
    padding-left: 2rem;
    background: transparent url(/images/quote.png) no-repeat 3rem top;
    padding-top: 3rem;
    padding-right: 2rem;
    background-size: 2rem; }
  /* line 1055, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .testimonials .carousel-testimonial,
  .testimonials .carousel-firma {
    font-size: 2rem; } }

/* line 1063, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.btn, [type=button],
[type=submit],
button {
  font-weight: 700;
  border-radius: 0.4rem; }
  /* line 1067, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .btn--small {
    font-size: 1.4rem;
    padding: 0 1rem;
    line-height: 3.5rem;
    height: auto; }
  /* line 1074, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .btn--register {
    font-size: 1.8rem;
    background-color: #0065fa;
    padding: 0 2rem;
    margin-left: 1.5rem; }
  /* line 1081, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .btn--white {
    background-color: #ffffff;
    color: #1A71EF; }
    /* line 1085, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .btn--white:focus {
      background-color: #ffffff;
      color: #1A71EF; }
  /* line 1091, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .btn--submit {
    margin-top: 2rem; }
  /* line 1095, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .btn--expand {
    padding-left: 3.7rem;
    background-image: url("/images/plus.png");
    background-repeat: no-repeat;
    background-position: left 1rem center; }
    /* line 1101, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .btn--expand--minus {
      background-image: url("/images/minus.png");
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }

/* line 1109, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.impressum {
  padding: 50px; }
  /* line 1112, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .impressum p {
    padding-bottom: 0; }
  /* line 1116, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .impressum ul li {
    display: block; }

/* line 1121, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
#eoi-success {
  border-radius: 0.4rem;
  display: none;
  padding: 1em;
  color: #154402;
  background: #cfffad;
  font-weight: bold;
  margin: 1em 0; }
  /* line 1130, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  #eoi-success p {
    margin: 0;
    padding: 0; }

/* line 1137, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
#prize .section__title {
  color: #000; }

/* line 1141, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
#prize .section__body {
  color: #000 !important; }

@media screen and (max-width: 960px) {
  /* line 1145, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  #prize .section--prize-1 {
    background-image: none;
    padding-top: 6rem !important; } }

/* line 1153, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
#tos_accepted {
  display: inline-block;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox; }

/* line 1159, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
.subsection--with-image {
  display: flex;
  flex-direction: row;
  background-color: #3788ff;
  color: #fff !important;
  padding: 2rem;
  border-radius: 0.5rem; }
  /* line 1167, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .subsection--with-image h4 {
    margin-top: 0; }
  /* line 1171, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .subsection--with-image .subsection-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%; }
  /* line 1178, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .subsection--with-image .subsection-image--extra-padding {
    padding: 4rem; }
  /* line 1182, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
  .subsection--with-image .subsection-text {
    display: block;
    align-items: center;
    justify-content: center;
    width: 66%; }
    /* line 1188, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .subsection--with-image .subsection-text .btn, .subsection--with-image .subsection-text [type=button], .subsection--with-image .subsection-text
    [type=submit], .subsection--with-image .subsection-text
    button {
      color: #3788ff !important;
      float: right; }
  @media only screen and (max-width: 960px) {
    /* line 1195, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .subsection--with-image .subsection-image {
      display: none; }
    /* line 1199, /home/digi/code/static/junite-landing-page/app/styles/_custom.scss */
    .subsection--with-image .subsection-text {
      width: 100%; } }
